import { AppBar, Button, ButtonProps, Container, CssBaseline, makeStyles, Theme, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import React from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import logo from './assets/logo.png';
import Home from './Home';
import Imprint from './Imprint';
import theme from './theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: '#000',
    },
    link: {
      fontWeight: 'bold',
      textTransform: 'none'
    },
    linkContainer: {
      display: 'flex',
      paddingLeft: 0,
      paddingRight: 0
    },
    logo: {
      width: 32,
      height: 32
    }
  })
)

const NavLink: React.FC<ButtonProps> = ({ href, startIcon, children }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Button startIcon={startIcon} onClick={() => history.push(href!)}><Typography className={classes.link}>{children}</Typography></Button>
  )
}

const App: React.FC = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <AppBar position="relative">
          <Toolbar className={classes.appBar}>
            <Container maxWidth="md" className={classes.linkContainer}>
              <NavLink href="/" startIcon={<img className={classes.logo} src={logo} />}>DEVelocity</NavLink>
              <div style={{ flex: 1 }} />
              <NavLink href="/contact">Kontakt</NavLink>
              <NavLink href="/imprint">Impressum</NavLink>

            </Container>
          </Toolbar>
        </AppBar>


        <Switch>
          <Route path="/contact" component={() => <Imprint />} />
          <Route path="/imprint" component={() => <Imprint />} />
          <Route component={() => <Home />} />
        </Switch>


      </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;
