import { Container, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import Title from './components/Title';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(8, 4)
        },
        text: {
            whiteSpace: 'pre-wrap'
        }
    })
)

const Imprint: React.FC = () => {
    const classes = useStyles()
    return (
        <Container maxWidth="md" className={classes.root}>
            <Title>
                Impressum
            </Title>
            <Typography className={classes.text}>
                <br />
                <b>DEVelocity GmbH</b><br />
                Seebüll 11<br />
                25927 Neukirchen<br />
                <br />
                Telefon: <Link underline="always" href="tel:+491632372109">0163 2372109</Link> <br />
                E-Mail: <Link underline="always" href="mailto:contact@develocity.gmbh">contact@develocity.gmbh</Link> <br />
                <br />
                <b>Handelsregister B Flensburg, HRB 13750FL</b><br />
                Vertreten durch<br />
                Christian Kirst, Geschäftsführer<br />
                Steuernummer: 15/291/34213<br />
                <br />
                <b>Verantwortlich für den Inhalt gemäß §55 Abs. 2 RStV</b><br />
                Christian Kirst, Geschäftsführer<br />
                Seebüll 11<br />
                25927 Neukirchen<br />
            </Typography>
        </Container>
    )
}

export default Imprint