import { createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import 'typeface-metropolis'

const defaultTheme = createMuiTheme()
const { breakpoints } = defaultTheme

const palette: PaletteOptions = {
    background: {
        default: '#050505',
        paper: '#222'
    },
    primary: {
        main: '#0AA',
        contrastText: '#fff'
        /*main: '#AD974F',
        dark: '#8E793E',
        contrastText: '#FFF'*/
    },
    secondary: {
        main: '#FFF',
        contrastText: '#000'
    },
    /*text: {
        secondary: '#FFF'
    },*/
    type: 'dark'
};

const typography: TypographyOptions = {
    fontFamily: 'Metropolis',
    h1: {
        fontWeight: 400,
        fontSize: '1.7rem',
        [breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    },
    /*h3: {
        fontFamily: 'Rouge Script',
        [breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    },
    h6: {
        fontWeight: 600
    },
    h4: {
        fontWeight: 600,
        [breakpoints.down('xs')]: {
            fontSize: '1.5rem'
        }
    },
    subtitle1: {
        fontWeight: 'bold'
    }*/
    //fontFamily: 'Lato'//'TypoPRO Bebas Neue'*/
}

export default createMuiTheme({
    typography,
    palette,
    overrides: {
        MuiTabs: {
            indicator: {
                height: 3
            }
        }
    }
});