import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(2)
        }
    })
)

const Title: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <Typography variant="h1" className={classes.title}>{children}</Typography>
    )
}

export default Title