import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import phone from './assets/phone.jpg';
import Title from './components/Title';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            backgroundImage: `url("${phone}")`,
            height: '50vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        link: {
            margin: theme.spacing(0, 4),
            color: 'white',
            cursor: 'pointer'
        },
        titleBox: {
            width: '80vw',
            maxWidth: 800,
            marginTop: -32,
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.spacing(4)
        }
    })
)

const Home: React.FC = () => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.imageContainer} />
            <Paper className={classes.titleBox}>
                <Title>
                    Wir entwickeln komplexe Multiplattform-Anwendungen in konkurrenzloser Geschwindigkeit!
                </Title>
                <Typography>
                    Wir nutzen neuste Technologien, um Cloudgestützte Anwendungen für mehrere parallel Plattformen zu entwickeln. Eine geteilte Codebase hält Entwicklungsaufwändge gering
                    und macht es möglich, selbst komplexe Anforderungen innerhalb kürzester Zeit umzusetzen.
                    Trotzdem fühlen sich unsere Anwendungen auf jeder Plattform nativ an, egal ob iOS, Android, Web oder Desktop.
                </Typography>
            </Paper>
        </>
    )
}

export default Home